<script>
  import { Link } from "svelte-routing";
  import { navigate } from "svelte-routing";  

  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";
  import { AuthClient } from "@dfinity/auth-client";
  import Auth from "components/Auth/Auth.svelte";


  const patternVue = "/assets/img/pattern_svelte.png";
  const componentBtn = "/assets/img/component-btn.png";
  const componentProfileCard = "/assets/img/component-profile-card.png";
  const componentInfoCard = "/assets/img/component-info-card.png";
  const componentInfo2 = "/assets/img/component-info-2.png";
  const componentMenu = "/assets/img/component-menu.png";
  const componentBtnPink = "/assets/img/component-btn-pink.png";
  const documentation = "/assets/img/documentation.png";
  const loginimage = "/assets/img/login.jpg";
  const profile = "/assets/img/profile.jpg";
  const landing = "/assets/img/landing.jpg";
  const background = "/assets/img/icwebmap.png";
  const logo = "../assets/img/logo_icmaps.png";
  let client;

  

</script>

<div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-sm">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
        <h3 class="text-3xl font-semibold">
          Installing service worker 
        </h3>
      </div>
      <!--body-->
      <div class="relative p-6 flex-wrap content-center">
        <p class="flex my-4 text-blueGray-500 text-lg leading-relaxed">
          Please wait one moment. We will make this domain more secure.
        </p>
        <div wire:loading class="flex mr-2 mt-2 justify-center">
          <svg class="animate-spin rounded-full bg-transparent border-2 border-transparent border-opacity-50 w-9 h-9" style="border-right-color: white; border-top-color: white;" viewBox="0 0 48 48"></svg>
        </div>
      </div>
      <!--footer-->
      <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
        
      </div>
    </div>
  </div>
</div>
<div class="opacity-25 fixed inset-0 z-40 bg-black"></div>


<IndexNavbar />
<section class="header relative pt-16 items-center flex h-screen max-h-860-px">
  <div class="container mx-auto items-center flex flex-wrap z-10">
    <div class="flex w-full px-4 pl-4">
      <div class="pt-2 sm:pt-0">
        <img class="object-cover pb-4" src="{logo}" alt="icmaps logo">
        <h2 class="font-semibold text-4xl text-blueGray-600 pl-4">
          ICMaps - Decentralized and scalable open source web map server
        </h2>
        <p class="mt-4 text-lg leading-relaxed text-blueGray-500 pl-4">
          ICMaps is a OGC WMTS server running on Internet Computer. You can publish your own maps as a decentralized wmts service and navigate on the map on your own Web3 page, Web2 page oder GIS tools like QGIS.
        </p>
        

        <p class="mt-4 text-lg leading-relaxed text-blueGray-500 pl-4">
          Please wait, we are securing the domain for you...
        </p>
        <progress>Loading...</progress><div id="status"></div>
      </div>
    </div>
    
  </div>
  <div class="flex w-0 sm:w-4/5 invisible sm:visible" style="
  height: 100%;background-image: url('{background}');
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: contain;">
    
    </div>
  <!--<div class="b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 md:shrink-0 bg-color:black"></div>-->
  <!--<img
    class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 md:shrink-0"
    src="{background}"
    alt="IC Web Map"
  />-->
</section>
<Footer />
